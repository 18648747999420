
import {
  defineComponent, onBeforeUnmount, onMounted, reactive, watch,
} from 'vue';
import { Modal } from 'ant-design-vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { useGradeBase } from '@/core/composables/GradeBase';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import storeSistema from '@/store/storeSistema';
import ServicoBoletos from '@/servicos/Financeiro/ServicoBoleto';
import { IConta } from '@/models/Entidades/Cadastros/Financeiro/IConta';
import { IDTORetornoBancario } from '@/models/DTO/Financeiro/Boletos/IDTORetornoBancario';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { IOption } from '@/core/models/AntDesign/IOption';
import SelecionarGenerico from '@/core/components/Tela/SelecionarGenerico.vue';
import { IParametrosConsultaTituloFinanceiro } from '@/models/ParametrosRequisicao/Financeiro/IParametrosConsultaTituloFinanceiro';
import ServicoTituloFinanceiroReceber from '@/servicos/Financeiro/ServicoTituloFinanceiroReceber';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import { EStatusTituloFinanceiro } from '@/models/Enumeradores/Financeiro/EStatusTituloFinanceiro';
import { EStatusRetornoBancario } from '@/models/Enumeradores/Financeiro/EStatusRetornoBancario';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import BuscarTitulosModal from '@/components/Financeiro/TitulosFinanceiros/BuscarTitulosModal.vue';
import ServicoParametros from '@/servicos/MeuSistema/ServicoParametros';
import SelecionarCategoriaPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarCategoriaPlanoConta.vue';
import { IBaixaTitulos, IBaixaTitulosMovimentoFinanceiro, IBaixaTitulosTituloFinanceiroBaixado } from '@/models/Entidades/Financeiro/BaixasTitulosFinanceiros/IBaixaTitulosFinanceiros';
import { IMovimentoFinanceiro } from '@/models/Entidades/Financeiro/MovimentosFinanceiros/IMovimentoFinanceiro';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import ServicoBaixaTitulosReceber from '@/servicos/Financeiro/ServicoBaixaTitulosReceber';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';
import { ETipoMovimentoFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoMovimentoFinanceiro';
import SelecionarConta from '@/components/Cadastros/Financeiro/SelecionarConta.vue';
import ServicoConta from '@/servicos/Cadastros/Financeiro/ServicoConta';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { ISalvamentoAutomatico } from '@/models/Entidades/MeuSistema/ISalvamentoAutomatico';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';
import MovimentoFinanceiroModal from './MovimentoFinanceiroModal.vue';
import ServicoMovimentoFinanceiro from '@/servicos/Financeiro/ServicoMovimentoFinanceiro';
import { ESituacaoBoleto } from '@/models/Enumeradores/Financeiro/ESituacaoBoleto';
import { EMovimentoFinanceiroBaixaReferente } from '@/models/Enumeradores/Financeiro/EMovimentoFinanceiroBaixaReferente';
import { IDTOTituloFinanceiroObterDetalhamentoVariosTitulos } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroObterDetalhamentoVariosTitulos';
import { IDTOTituloFinanceiroDetalhamento } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroDetalhamento';
import { ETipoDetalhamentoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoDetalhamentoTituloFinanceiro';
import UtilitarioFinanceiro from '@/core/utilitarios/UtilitarioFinanceiro';

export default defineComponent({
  name: 'ImportacaoRetornoBancarioModal',
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
    empresa: {
      type: Number,
      required: true,
    },
    conta: {
      type: Number,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    SelecionarGenerico,
    SelecionarData,
    CampoNumerico,
    BuscarTitulosModal,
    SelecionarCategoriaPlanoConta,
    RequisicaoModal,
    SelecionarConta,
    PreviewPdf,
    MovimentoFinanceiroModal,
  },
  emits: ['update:conta', 'update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const {
      telaBase, apresentarMensagemAlerta, apresentarMensagemSucesso, apresentarMensagemErro,
    } = useTelaBase();
    const {
      modalBase, apresentarBarraProgresso, ocultarBarraProgresso, apresentarRetornoRequisicao,
    } = useModalBase(props, emit);
    const { gradeBase } = useGradeBase();
    const servicoBoletos = new ServicoBoletos();
    const servicoConta = new ServicoConta();
    const servicoTituloFinanceiroReceber = new ServicoTituloFinanceiroReceber();
    const servicoBaixaTitulosReceber = new ServicoBaixaTitulosReceber();
    const servicoParametros = new ServicoParametros();
    const servicoSistema = new ServicoSistema();
    const servicoMovimentoFinanceiro = new ServicoMovimentoFinanceiro();
    servicoBoletos.requisicaoSistema();
    servicoConta.requisicaoSistema();
    servicoTituloFinanceiroReceber.requisicaoSistema();
    servicoBaixaTitulosReceber.requisicaoSistema();
    servicoParametros.requisicaoSistema();
    servicoMovimentoFinanceiro.requisicaoSistema();

    const state = reactive({
      codigoConta: 0,
      conta: {} as IConta,
      retornos: [] as IDTORetornoBancario[],
      retornoSelecionado: {} as IDTORetornoBancario,
      operacaoLancamento: {} as ITelaOperacao,
      movimentoFinanceiroLancamento: {} as IMovimentoFinanceiro,
      cacheAutomatico: {} as ISalvamentoAutomatico,
      cacheDisponivel: false,
      tiposOcorrencia: [] as IOption[],
      nomeArquivo: '',
      indexSelecionado: -1,
      indexSelecionados: [] as number[],
      buscaRapida: '',
      tipoOcorrenciaSelecionado: 0,
      categoriaDescontos: 0,
      categoriaMultaJuros: 0,
      categoriaTaxas: 0,
      enviandoArquivo: false,
      painelUpload: 1,
      exibirPainelTitulo: false,
      exibirBuscaTitulo: false,
      exibirLancamento: false,
      selecaoMultipla: false,
      selecionarTodos: false,
      ignorarTaxas: false,
      detalhamentos: [] as IDTOTituloFinanceiroDetalhamento[],
      arquivosPdf: [] as IArquivoPdf[],
      totalAbatimentosTituloSelecionado: 0,
      apresentarSugestaoZerarDescontoEUsarAbatimento: false,
      valorJurosMultaSugestaoTituloSelecionado: 0,
      baixasValidas: false,
    });

    async function limparTela(limparConta: boolean) {
      state.retornos = [] as IDTORetornoBancario[];
      state.retornoSelecionado = {} as IDTORetornoBancario;
      state.tiposOcorrencia = [] as IOption[];
      state.indexSelecionado = -1;
      state.indexSelecionados = [] as number[];
      state.selecionarTodos = false;
      state.nomeArquivo = '';
      state.buscaRapida = '';
      state.tipoOcorrenciaSelecionado = 0;
      state.enviandoArquivo = false;
      state.painelUpload = 1;
      state.exibirPainelTitulo = false;
      state.exibirBuscaTitulo = false;
      state.selecaoMultipla = false;
      state.totalAbatimentosTituloSelecionado = 0;
      state.ignorarTaxas = false;
      if (limparConta) {
        state.codigoConta = 0;
        state.conta = {} as IConta;
        state.categoriaDescontos = 0;
        state.categoriaMultaJuros = 0;
        state.categoriaTaxas = 0;
      }
    }

    function obterIdentificadorCache(): string {
      return `IMPORTACAO_RETORNO_BANCARIO_${state.codigoConta}_${storeSistema.getters.codigoUsuarioAutenticado()}`;
    }

    function salvarCache() {
      const identificador = obterIdentificadorCache();
      state.cacheAutomatico.data = UtilitarioData.obterDataAtual();
      state.cacheAutomatico.codigoEmpresa = props.empresa;
      state.cacheAutomatico.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
      state.cacheAutomatico.identificador = telaBase.identificadorRecurso;
      state.cacheAutomatico.dados = JSON.stringify(state.retornos);
      servicoSistema.salvamentoAutomaticoLocalStorage(`${identificador}`, state.cacheAutomatico);
      state.cacheDisponivel = false;
    }

    async function recuperarCache() {
      if (UtilitarioGeral.valorValido(state.cacheAutomatico.dados)) {
        const cache = JSON.parse(state.cacheAutomatico.dados);
        if (UtilitarioGeral.objetoValido(cache)) {
          servicoSistema.removerSalvamentoAutomaticoLocalStorage(obterIdentificadorCache());
          state.retornos = cache;
          state.cacheDisponivel = false;
          state.painelUpload = 0;

          const codigosTitulosFinanceiros:number[] = [];
          state.indexSelecionados.forEach((i) => {
            codigosTitulosFinanceiros.push(state.retornos[i].tituloFinanceiro.codigo);
          });
          const dadosBuscaDetalhamentos:IDTOTituloFinanceiroObterDetalhamentoVariosTitulos = {} as IDTOTituloFinanceiroObterDetalhamentoVariosTitulos;
          dadosBuscaDetalhamentos.empresas = [props.empresa];
          dadosBuscaDetalhamentos.tipoTituloFinanceiro = ETipoTituloFinanceiro.Receber;
          dadosBuscaDetalhamentos.codigosTituloFinanceiros = codigosTitulosFinanceiros;
          state.detalhamentos = await servicoTituloFinanceiroReceber.obterDetalhamentosVariosTitulos(dadosBuscaDetalhamentos);
          apresentarMensagemSucesso('Informações recuperadas com sucesso!');
        }
      }
    }

    async function verificarCache() {
      const identificador = obterIdentificadorCache();
      const salvamentoAutomaticoLocalStorage = await servicoSistema.obterSalvamentoAutomaticoLocalStorage(identificador);
      if (UtilitarioGeral.objetoValido(salvamentoAutomaticoLocalStorage)) {
        state.cacheAutomatico = salvamentoAutomaticoLocalStorage;
        if (UtilitarioData.validaDataPreenchida(state.cacheAutomatico.data)) {
          const cache = JSON.parse(state.cacheAutomatico.dados);
          state.cacheAutomatico = cache;
          state.cacheDisponivel = true;
        }
      }
    }

    async function buscarConta() {
      if (state.codigoConta > 0 && state.codigoConta !== undefined && state.codigoConta !== state.conta.codigo) {
        state.conta = await servicoConta.obter(state.codigoConta);
      }
    }

    function popularTiposOcorrencia() {
      state.tiposOcorrencia = [] as IOption[];
      const todos = {} as IOption;
      todos.label = 'Todos';
      todos.value = 0;
      state.tiposOcorrencia.push(todos);
      state.retornos.forEach((r) => {
        const index = state.tiposOcorrencia.findIndex((t) => t.value === r.codigoOcorrencia);
        if (index === -1) {
          const ocorrencia = {} as IOption;
          ocorrencia.label = r.descricaoOcorrencia;
          ocorrencia.value = r.codigoOcorrencia;
          state.tiposOcorrencia.push(ocorrencia);
        }
      });
    }

    function filtrarOcorrencias() {
      state.retornos.forEach((r, indexR) => {
        if (r.codigoOcorrencia === state.tipoOcorrenciaSelecionado || state.tipoOcorrenciaSelecionado === 0) {
          if (state.buscaRapida !== '') {
            if (UtilitarioData.aplicaFormatoData(r.dataVencimento) === state.buscaRapida
            || UtilitarioData.aplicaFormatoData(r.dataOcorrencia) === state.buscaRapida
            || Number(r.nossoNumero) === Number(state.buscaRapida)
            || r.numeroDocumento.toLocaleLowerCase().includes(state.buscaRapida.toLocaleLowerCase())
            || r.valorTitulo === Number(state.buscaRapida.replace(',', '.'))) {
              state.retornos[indexR].visivel = true;
            } else if ('não encontrado'.includes(state.buscaRapida.toLowerCase()) && r.status === EStatusRetornoBancario.NaoEncontrado) {
              state.retornos[indexR].visivel = true;
            } else if ('baixado'.includes(state.buscaRapida.toLowerCase()) && r.status === EStatusRetornoBancario.Baixado) {
              state.retornos[indexR].visivel = true;
            } else if ('pendente'.includes(state.buscaRapida.toLowerCase()) && r.status === EStatusRetornoBancario.Pendente) {
              state.retornos[indexR].visivel = true;
            } else if ('negativado'.includes(state.buscaRapida.toLowerCase()) && r.status === EStatusRetornoBancario.Negativado) {
              state.retornos[indexR].visivel = true;
            } else if ('protestado'.includes(state.buscaRapida.toLowerCase()) && r.status === EStatusRetornoBancario.Protestado) {
              state.retornos[indexR].visivel = true;
            } else {
              state.retornos[indexR].visivel = false;
            }
          } else {
            state.retornos[indexR].visivel = true;
          }
        } else {
          state.retornos[indexR].visivel = false;
        }
      });
    }

    function selecionarTodos() {
      state.selecionarTodos = !state.selecionarTodos;
      state.indexSelecionados = [] as number[];
      if (state.selecionarTodos) {
        state.retornos.forEach((r, indexR) => {
          if ((r.status === EStatusRetornoBancario.Pendente || r.status === EStatusRetornoBancario.Negativado || EStatusRetornoBancario.Protestado) && r.codigoOcorrencia === 6) {
            state.indexSelecionados.push(indexR);
          }
        });
      }
    }

    function ignorarTaxas() {
      state.retornos.forEach((r, index) => {
        state.retornos[index].ignorarTaxas = state.ignorarTaxas;
      });
    }

    function usarValorJurosMultaSugestao() {
      state.retornos[state.indexSelecionado].valorJurosMulta = state.valorJurosMultaSugestaoTituloSelecionado;
      state.valorJurosMultaSugestaoTituloSelecionado = 0;
    }

    function usarAbatimentoEZerarDesconto() {
      state.retornos[state.indexSelecionado].valorAbatimento = state.totalAbatimentosTituloSelecionado;
      state.retornos[state.indexSelecionado].valorDescontos = 0;
      state.apresentarSugestaoZerarDescontoEUsarAbatimento = false;
      state.totalAbatimentosTituloSelecionado = 0;
    }

    function apresentarSugestaoPossivelJurosMulta(): boolean {
      if (state.indexSelecionado >= 0) {
        if (state.retornos[state.indexSelecionado].valorJurosMulta === 0) {
          const valorLiquidoTitulo = (state.retornos[state.indexSelecionado].valorTitulo - (state.retornos[state.indexSelecionado].valorAbatimento + state.retornos[state.indexSelecionado].valorDescontos + state.retornos[state.indexSelecionado].valorTaxas));
          if (state.retornos[state.indexSelecionado].valorPago > valorLiquidoTitulo && valorLiquidoTitulo > 0) {
            state.valorJurosMultaSugestaoTituloSelecionado = UtilitarioFinanceiro.valorDecimal2Casas(state.retornos[state.indexSelecionado].valorPago - valorLiquidoTitulo);
            return true;
          }
        }
      }
      state.valorJurosMultaSugestaoTituloSelecionado = 0;
      return false;
    }

    async function selecionarRetorno(index: number) {
      if (state.selecaoMultipla) {
        state.selecaoMultipla = false;
        return;
      }
      state.apresentarSugestaoZerarDescontoEUsarAbatimento = false;
      state.totalAbatimentosTituloSelecionado = 0;
      state.indexSelecionado = index;
      state.retornoSelecionado = state.retornos[index];

      if (state.retornos[index].status === EStatusRetornoBancario.Pendente) {
        if (UtilitarioGeral.validaLista(state.detalhamentos)) {
          const detalhamentosTitulo = state.detalhamentos.filter((c) => c.codigoTituloFinanceiro === state.retornos[index].tituloFinanceiro.codigo);
          if (UtilitarioGeral.validaLista(detalhamentosTitulo)) {
            const detalhamentosAbatimentos = detalhamentosTitulo.filter((detalhamentosTitulo) => detalhamentosTitulo.tipo === ETipoDetalhamentoTituloFinanceiro.Abatimento);
            if (UtilitarioGeral.validaLista(detalhamentosAbatimentos)) {
              state.totalAbatimentosTituloSelecionado = UtilitarioFinanceiro.valorDecimal2Casas(detalhamentosAbatimentos.reduce((valorAcumulado, detalhamento) => valorAcumulado + detalhamento.valor, 0));
              if (state.totalAbatimentosTituloSelecionado > 0 && state.retornos[index].valorAbatimento === 0 && state.retornos[index].valorDescontos === 0) {
                state.retornos[index].valorAbatimento = state.totalAbatimentosTituloSelecionado;
              } else if (state.totalAbatimentosTituloSelecionado > 0 && state.retornos[index].valorAbatimento === 0 && state.retornos[index].valorDescontos > 0 && state.retornos[index].valorDescontos <= state.totalAbatimentosTituloSelecionado) {
                state.apresentarSugestaoZerarDescontoEUsarAbatimento = true;
              }
            }
          }
        }
      }

      if (state.retornos[index].status === EStatusRetornoBancario.Baixado && !state.retornos[index].atualizado) {
        const dadosBaixa = await servicoTituloFinanceiroReceber.ObterDadosBaixa(props.empresa, state.retornos[index].tituloFinanceiro.codigo);
        state.retornos[index].valorJurosMulta = dadosBaixa.jurosMulta;
        state.retornos[index].valorDescontos = dadosBaixa.desconto;
        state.retornos[index].atualizado = true;
      }
      state.exibirPainelTitulo = true;
    }

    function alterarSelecao(index: number) {
      state.selecaoMultipla = true;
      const indexSelecionado = state.indexSelecionados.findIndex((i) => i === index);
      if (indexSelecionado === -1) {
        if (state.retornos[index].status === EStatusRetornoBancario.Pendente || state.retornos[index].status === EStatusRetornoBancario.Negativado || state.retornos[index].status === EStatusRetornoBancario.Protestado) {
          state.indexSelecionados.push(index);
        }
      } else {
        state.indexSelecionados.splice(indexSelecionado, 1);
      }
    }

    function fecharSelecao() {
      state.exibirPainelTitulo = false;
      state.indexSelecionado = -1;
      state.retornoSelecionado = {} as IDTORetornoBancario;
    }

    function selecionarArquivoUpload() {
      const elemento = document.getElementById('selecionarArquivo');
      if (elemento !== null) {
        elemento.click();
      }
    }

    async function obterTitulosVinculadosImportados(titulosVinculados: IDTOTituloFinanceiro[]) {
      const codigosImportados = [] as string[];
      state.retornos.forEach((r) => {
        const index = titulosVinculados.findIndex((t) => t.codigo === r.codigoTitulo);
        if (index === -1 && UtilitarioGeral.valorValido(r.codigoImportado)) {
          codigosImportados.push(String(r.codigoImportado));
        }
      });
      const titulosImportados = await servicoTituloFinanceiroReceber.obterTitulosImportados(props.empresa, codigosImportados);
      titulosImportados.forEach((t) => {
        titulosVinculados.push(t);
      });
      return titulosVinculados;
    }

    async function obterTitulosVinculados() {
      const parametrosConsulta = {} as IParametrosConsultaTituloFinanceiro;
      parametrosConsulta.empresas = [props.empresa];

      const titulos = [] as IDTOTituloFinanceiro[];
      state.retornos.forEach((r) => {
        const titulo = {} as IDTOTituloFinanceiro;
        titulo.codigo = r.codigoTitulo;
        titulo.codigoImportado = r.codigoImportado;
        titulo.nossoNumero = r.nossoNumero;
        titulos.push(titulo);
      });
      let titulosVinculados = await servicoTituloFinanceiroReceber.buscarTitulosVinculados(titulos, props.empresa, state.codigoConta);
      titulosVinculados = await obterTitulosVinculadosImportados(titulosVinculados);
      state.retornos.forEach((r, indexR) => {
        state.retornos[indexR].visivel = true;
        const index = titulosVinculados.findIndex((t) => (r.codigoTitulo > 0 && t.codigo === r.codigoTitulo) || (UtilitarioGeral.valorValido(t.codigoImportado) && t.codigoImportado === r.codigoImportado) || (r.codigoTitulo === 0 && t.nossoNumero === r.nossoNumero));
        if (index >= 0) {
          state.retornos[indexR].tituloFinanceiro = titulosVinculados[index];
          if (titulosVinculados[index].status === EStatusTituloFinanceiro.Pendente || titulosVinculados[index].status === EStatusTituloFinanceiro.Vencido) {
            if (titulosVinculados[index].situacaoBoleto === ESituacaoBoleto.Negativado) {
              state.retornos[indexR].status = EStatusRetornoBancario.Negativado;
            } else if (titulosVinculados[index].situacaoBoleto === ESituacaoBoleto.Protestado) {
              state.retornos[indexR].status = EStatusRetornoBancario.Protestado;
            } else {
              state.retornos[indexR].status = EStatusRetornoBancario.Pendente;
            }
            if (state.retornos[indexR].codigoOcorrencia === 6) {
              state.indexSelecionados.push(indexR);
            }
            state.retornos[indexR].categoriaPrincipal = state.retornos[indexR].tituloFinanceiro.codigoPlanoContaCategoria;
            state.retornos[indexR].categoriaMultaJuros = state.categoriaMultaJuros;
            state.retornos[indexR].categoriaTaxas = state.categoriaTaxas;
            state.retornos[indexR].categoriaDescontos = state.categoriaDescontos;
          } else {
            state.retornos[indexR].status = EStatusRetornoBancario.Baixado;
          }
          state.retornos[indexR].nomePagador = state.retornos[indexR].tituloFinanceiro.nomePessoa;
        } else {
          state.retornos[indexR].status = EStatusRetornoBancario.NaoEncontrado;
        }
      });
      salvarCache();

      const codigosTitulosFinanceiros:number[] = [];
      state.indexSelecionados.forEach((i) => {
        codigosTitulosFinanceiros.push(state.retornos[i].tituloFinanceiro.codigo);
      });
      const dadosBuscaDetalhamentos:IDTOTituloFinanceiroObterDetalhamentoVariosTitulos = {} as IDTOTituloFinanceiroObterDetalhamentoVariosTitulos;
      dadosBuscaDetalhamentos.empresas = [props.empresa];
      dadosBuscaDetalhamentos.tipoTituloFinanceiro = ETipoTituloFinanceiro.Receber;
      dadosBuscaDetalhamentos.codigosTituloFinanceiros = codigosTitulosFinanceiros;
      state.detalhamentos = await servicoTituloFinanceiroReceber.obterDetalhamentosVariosTitulos(dadosBuscaDetalhamentos);
    }

    async function enviarArquivoLeitura(arquivo: any) {
      limparTela(false);
      state.enviandoArquivo = true;
      state.retornoSelecionado = {} as IDTORetornoBancario;
      state.indexSelecionado = -1;
      if (state.conta.codigoBanco === 5 || state.conta.codigoBanco === 220) {
        state.ignorarTaxas = true;
      }
      const retorno = await servicoBoletos.lerRetorno(props.empresa, state.conta.codigoBanco, arquivo);
      state.nomeArquivo = arquivo.name;
      if (UtilitarioGeral.validaLista(retorno)) {
        if (retorno.length === 1 && UtilitarioGeral.valorValido(retorno[0].mensagemRetorno)) {
          apresentarMensagemAlerta(retorno[0].mensagemRetorno);
        } else {
          state.retornos = retorno;
          state.painelUpload = 0;
          popularTiposOcorrencia();
          await obterTitulosVinculados();
        }
      } else {
        apresentarMensagemAlerta('Ocorreu um erro ao ler o arquivo');
      }
      state.enviandoArquivo = false;
    }

    async function uploadArquivoSelecionado(event: any) {
      await enviarArquivoLeitura(event.target.files[0]);
    }

    async function uploadArquivoArrastado(event: any) {
      await enviarArquivoLeitura(event.dataTransfer.files[0]);
    }

    function abrirBuscaTitulo() {
      state.exibirBuscaTitulo = true;
    }

    function desvincularTitulo() {
      state.retornoSelecionado.tituloSelecionadoManualmente = true;
      state.retornoSelecionado.tituloFinanceiro = {}as IDTOTituloFinanceiro;
      state.retornoSelecionado.tituloFinanceiro.codigo = 0;
    }

    function vincularTitulo(titulos: IDTOTituloFinanceiro[]) {
      state.retornoSelecionado.tituloSelecionadoManualmente = true;
      state.retornoSelecionado.tituloFinanceiro = titulos[0];
      state.retornoSelecionado.numeroDocumento = titulos[0].numeroTitulo;
      state.retornoSelecionado.dataVencimento = titulos[0].dataVencimento;
      state.retornoSelecionado.categoriaPrincipal = titulos[0].codigoPlanoContaCategoria;
      state.retornoSelecionado.categoriaMultaJuros = state.categoriaMultaJuros;
      state.retornoSelecionado.categoriaTaxas = state.categoriaTaxas;
      state.retornoSelecionado.categoriaDescontos = state.categoriaDescontos;
      state.retornoSelecionado.nomePagador = titulos[0].nomePessoa;
      if (titulos[0].situacaoBoleto === ESituacaoBoleto.Negativado) {
        state.retornoSelecionado.status = EStatusRetornoBancario.Negativado;
      } else if (titulos[0].situacaoBoleto === ESituacaoBoleto.Protestado) {
        state.retornoSelecionado.status = EStatusRetornoBancario.Protestado;
      } else {
        state.retornoSelecionado.status = EStatusRetornoBancario.Pendente;
      }
      state.retornos[state.indexSelecionado] = state.retornoSelecionado;
      salvarCache();
    }

    async function vincularMovimentoCriado(codigoMovimento: number) {
      const parametrosConsulta = {} as IParametrosConsultaTituloFinanceiro;
      parametrosConsulta.empresas = [props.empresa];
      const titulos = await servicoMovimentoFinanceiro.obterTitulosBaixados(codigoMovimento, props.empresa);
      const codigos = [] as number[];
      codigos.push(titulos[0].codigoTituloFinanceiro);
      parametrosConsulta.codigosSelecionados = codigos;
      const listaPaginada = await servicoTituloFinanceiroReceber.buscarTitulos(parametrosConsulta);
      let titulosVinculados = [] as IDTOTituloFinanceiro[];
      titulosVinculados = listaPaginada.dados;
      state.retornos[state.indexSelecionado].tituloFinanceiro = titulosVinculados[0];
      state.retornos[state.indexSelecionado].status = EStatusRetornoBancario.Baixado;
      state.indexSelecionados.push(state.indexSelecionado);
      state.retornos[state.indexSelecionado].categoriaPrincipal = state.retornos[state.indexSelecionado].tituloFinanceiro.codigoPlanoContaCategoria;
      state.retornos[state.indexSelecionado].categoriaMultaJuros = state.categoriaMultaJuros;
      state.retornos[state.indexSelecionado].categoriaTaxas = state.categoriaTaxas;
      state.retornos[state.indexSelecionado].categoriaDescontos = state.categoriaDescontos;
      state.retornos[state.indexSelecionado].nomePagador = state.retornos[state.indexSelecionado].tituloFinanceiro.nomePessoa;
      const indexSelecionado = state.indexSelecionados.findIndex((i) => i === state.indexSelecionado);
      state.indexSelecionados.splice(indexSelecionado, 1);
      salvarCache();
    }

    function criarMovimentoFinanceiro() {
      state.operacaoLancamento = {} as ITelaOperacao;
      state.operacaoLancamento.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacaoLancamento.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.operacaoLancamento.codigoRegistro = 0;
      state.operacaoLancamento.empresaSelecionada = props.empresa;
      state.movimentoFinanceiroLancamento = {} as IMovimentoFinanceiro;
      state.movimentoFinanceiroLancamento.tipoMovimento = ETipoMovimentoFinanceiro.Recebimento;
      state.movimentoFinanceiroLancamento.influenciaSaldo = true;
      state.movimentoFinanceiroLancamento.conciliado = true;
      state.movimentoFinanceiroLancamento.recursoOrigem = 'BAIXAS_CONTAS_RECEBER';
      state.movimentoFinanceiroLancamento.descricao = `BAIXA DO BOLETO ${state.retornoSelecionado.numeroDocumento}`;
      state.movimentoFinanceiroLancamento.codigoConta = state.codigoConta;
      state.movimentoFinanceiroLancamento.codigoEmpresa = props.empresa;
      state.movimentoFinanceiroLancamento.dataMovimento = state.retornoSelecionado.dataCredito;
      state.movimentoFinanceiroLancamento.codigoImportado = String(state.retornoSelecionado.codigoTitulo);
      state.movimentoFinanceiroLancamento.identificadorMovimento = `RET${state.retornoSelecionado.numeroDocumento}`;
      state.movimentoFinanceiroLancamento.valor = state.retornoSelecionado.valorTitulo;
      state.exibirLancamento = true;
    }

    async function obterParametros() {
      const categoriaDescontos = await servicoParametros.obterValor(props.empresa, 'PAR_FIN_RECEBER_CATEGORIA_PADRAO_DESCONTO');
      const categoriaMultaJuros = await servicoParametros.obterValor(props.empresa, 'PAR_FIN_RECEBER_CATEGORIA_PADRAO_JUROS_MULTA');
      const categoriaTaxas = await servicoParametros.obterValor(props.empresa, 'PAR_FIN_RECEBER_CATEGORIA_PADRAO_TAXAS');

      if (categoriaDescontos !== undefined && categoriaDescontos.valor !== '') {
        state.categoriaDescontos = Number(categoriaDescontos.valor);
      }

      if (categoriaMultaJuros !== undefined && categoriaMultaJuros.valor !== '') {
        state.categoriaMultaJuros = Number(categoriaMultaJuros.valor);
      }

      if (categoriaTaxas !== undefined && categoriaTaxas.valor !== '') {
        state.categoriaTaxas = Number(categoriaTaxas.valor);
      }
    }

    function obterMovimentoFinanceiroPreenchidoPorDetalhamento(index: number, detalhamento:IDTOTituloFinanceiroDetalhamento, totalAbatimentos:number, lancarTituloBaixado: boolean) {
      const movimento = {} as IBaixaTitulosMovimentoFinanceiro;

      movimento.codigoTituloFinanceiroOrigem = state.retornos[index].tituloFinanceiro.codigo;
      movimento.movimentoFinanceiro = {} as IMovimentoFinanceiro;
      movimento.movimentoFinanceiro.identificadorMovimento = `RET${state.retornos[index].tituloFinanceiro.numeroTitulo}-${UtilitarioGeral.gerarIdentificadorTexto(detalhamento.descricao).toUpperCase()}-`;
      movimento.movimentoFinanceiro.codigoConta = state.codigoConta;
      movimento.movimentoFinanceiro.codigoPlanoContaCategoria = detalhamento.codigoPlanoContaCategoria;
      movimento.movimentoFinanceiro.codigoEmpresa = props.empresa;
      movimento.movimentoFinanceiro.codigoPessoa = state.retornos[index].tituloFinanceiro.codigoPessoa;
      movimento.movimentoFinanceiro.codigoTipoDocumentoFinanceiro = state.retornos[index].tituloFinanceiro.codigoTipoDocumentoFinanceiro;
      movimento.movimentoFinanceiro.descricao = `BAIXA DO BOLETO ${state.retornos[index].tituloFinanceiro.numeroTitulo} - ${detalhamento.descricao}`;
      movimento.movimentoFinanceiro.observacoes = `BAIXA EFETUADA PELA IMPORTAÇÃO DO RETORNO ${state.nomeArquivo}`;
      movimento.movimentoFinanceiro.conciliado = true;
      movimento.movimentoFinanceiro.influenciaSaldo = true;
      movimento.movimentoFinanceiro.dataMovimento = state.retornos[index].dataCredito;
      movimento.movimentoFinanceiro.marcadores = [];
      movimento.movimentoFinanceiro.recursoOrigem = 'BAIXAS_CONTAS_RECEBER';
      if (state.retornos[index].codigoTitulo !== state.retornos[index].tituloFinanceiro.codigo) {
        movimento.movimentoFinanceiro.codigoImportado = state.retornos[index].codigoImportado;
      }
      movimento.movimentoFinanceiro.anexos = [];
      movimento.movimentoFinanceiro.valor = detalhamento.valor;
      movimento.titulosBaixados = [] as IBaixaTitulosTituloFinanceiroBaixado[];

      if (detalhamento.tipo === ETipoDetalhamentoTituloFinanceiro.Informativo) {
        movimento.referente = EMovimentoFinanceiroBaixaReferente.BaixaTitulo;
        movimento.movimentoFinanceiro.tipoMovimento = ETipoMovimentoFinanceiro.Recebimento;
        if (lancarTituloBaixado) {
          const tituloBaixa = {} as IBaixaTitulosTituloFinanceiroBaixado;
          tituloBaixa.codigo = 0;
          tituloBaixa.codigoBaixaTitulosMovimentoFinanceiro = 0;
          tituloBaixa.codigoTituloFinanceiro = state.retornos[index].tituloFinanceiro.codigo;
          tituloBaixa.valorJurosMulta = state.retornos[index].valorJurosMulta;
          tituloBaixa.valorDesconto = state.retornos[index].valorDescontos;
          tituloBaixa.valorTaxa = state.retornos[index].ignorarTaxas ? 0 : state.retornos[index].valorTaxas;
          tituloBaixa.valorAbatimento = totalAbatimentos;
          tituloBaixa.valorAcrescimo = 0;
          tituloBaixa.valorPago = state.retornos[index].valorTitulo;
          movimento.titulosBaixados.push(tituloBaixa);
        }
      } else if (detalhamento.tipo === ETipoDetalhamentoTituloFinanceiro.Abatimento) {
        movimento.referente = EMovimentoFinanceiroBaixaReferente.Abatimento;
        movimento.movimentoFinanceiro.tipoMovimento = ETipoMovimentoFinanceiro.Pagamento;
      }
      return movimento;
    }

    function obterMovimentoFinanceiroPreenchido(index: number) {
      const movimento = {} as IBaixaTitulosMovimentoFinanceiro;
      movimento.referente = EMovimentoFinanceiroBaixaReferente.BaixaTitulo;
      movimento.codigoTituloFinanceiroOrigem = state.retornos[index].tituloFinanceiro.codigo;
      movimento.movimentoFinanceiro = {} as IMovimentoFinanceiro;
      movimento.movimentoFinanceiro.identificadorMovimento = `RET${state.retornos[index].tituloFinanceiro.numeroTitulo}`;
      movimento.movimentoFinanceiro.codigoConta = state.codigoConta;
      movimento.movimentoFinanceiro.codigoPlanoContaCategoria = state.retornos[index].categoriaPrincipal;
      movimento.movimentoFinanceiro.codigoEmpresa = props.empresa;
      movimento.movimentoFinanceiro.codigoPessoa = state.retornos[index].tituloFinanceiro.codigoPessoa;
      movimento.movimentoFinanceiro.codigoTipoDocumentoFinanceiro = state.retornos[index].tituloFinanceiro.codigoTipoDocumentoFinanceiro;
      movimento.movimentoFinanceiro.descricao = `BAIXA DO BOLETO ${state.retornos[index].tituloFinanceiro.numeroTitulo}`;
      movimento.movimentoFinanceiro.observacoes = `BAIXA EFETUADA PELA IMPORTAÇÃO DO RETORNO ${state.nomeArquivo}`;
      movimento.movimentoFinanceiro.conciliado = true;
      movimento.movimentoFinanceiro.influenciaSaldo = true;
      movimento.movimentoFinanceiro.tipoMovimento = ETipoMovimentoFinanceiro.Recebimento;
      movimento.movimentoFinanceiro.dataMovimento = state.retornos[index].dataCredito;
      movimento.movimentoFinanceiro.marcadores = [];
      movimento.movimentoFinanceiro.recursoOrigem = 'BAIXAS_CONTAS_RECEBER';
      if (state.retornos[index].codigoTitulo !== state.retornos[index].tituloFinanceiro.codigo) {
        movimento.movimentoFinanceiro.codigoImportado = state.retornos[index].codigoImportado;
      }
      movimento.movimentoFinanceiro.anexos = [];
      movimento.movimentoFinanceiro.valor = state.retornos[index].valorTitulo;
      movimento.titulosBaixados = [] as IBaixaTitulosTituloFinanceiroBaixado[];
      const tituloBaixa = {} as IBaixaTitulosTituloFinanceiroBaixado;
      tituloBaixa.codigo = 0;
      tituloBaixa.codigoBaixaTitulosMovimentoFinanceiro = 0;
      tituloBaixa.codigoTituloFinanceiro = state.retornos[index].tituloFinanceiro.codigo;
      tituloBaixa.valorJurosMulta = state.retornos[index].valorJurosMulta;
      tituloBaixa.valorDesconto = state.retornos[index].valorDescontos;
      tituloBaixa.valorTaxa = state.retornos[index].ignorarTaxas ? 0 : state.retornos[index].valorTaxas;
      tituloBaixa.valorAbatimento = 0;
      tituloBaixa.valorAcrescimo = 0;
      tituloBaixa.valorPago = state.retornos[index].valorTitulo;
      movimento.titulosBaixados.push(tituloBaixa);
      return movimento;
    }

    function obterMovimentoFinanceiroOutrosValores(index: number, tipo: string) {
      const movimento = {} as IBaixaTitulosMovimentoFinanceiro;
      movimento.codigoTituloFinanceiroOrigem = state.retornos[index].tituloFinanceiro.codigo;
      movimento.movimentoFinanceiro = {} as IMovimentoFinanceiro;
      movimento.movimentoFinanceiro.codigoConta = state.codigoConta;
      movimento.movimentoFinanceiro.codigoEmpresa = props.empresa;
      movimento.movimentoFinanceiro.codigoPessoa = state.retornos[index].tituloFinanceiro.codigoPessoa;
      movimento.movimentoFinanceiro.codigoTipoDocumentoFinanceiro = state.retornos[index].tituloFinanceiro.codigoTipoDocumentoFinanceiro;
      movimento.movimentoFinanceiro.observacoes = `BAIXA EFETUADA PELA IMPORTAÇÃO DO RETORNO ${state.nomeArquivo}`;
      movimento.movimentoFinanceiro.descricao = `BAIXA DO BOLETO ${state.retornos[index].tituloFinanceiro.numeroTitulo}`;
      movimento.movimentoFinanceiro.dataMovimento = state.retornos[index].dataCredito;
      movimento.movimentoFinanceiro.conciliado = true;
      movimento.movimentoFinanceiro.influenciaSaldo = true;
      movimento.movimentoFinanceiro.marcadores = [];
      if (tipo === 'D') {
        movimento.referente = EMovimentoFinanceiroBaixaReferente.Desconto;
        movimento.movimentoFinanceiro.identificadorMovimento = `RET${state.retornos[index].tituloFinanceiro.numeroTitulo}DESC`;
        movimento.movimentoFinanceiro.tipoMovimento = ETipoMovimentoFinanceiro.Pagamento;
        movimento.movimentoFinanceiro.codigoPlanoContaCategoria = state.retornos[index].categoriaDescontos;
        movimento.movimentoFinanceiro.descricao += ' DESCONTO';
        movimento.movimentoFinanceiro.valor = state.retornos[index].valorDescontos;
      } else if (tipo === 'M') {
        movimento.referente = EMovimentoFinanceiroBaixaReferente.JurosMulta;
        movimento.movimentoFinanceiro.identificadorMovimento = `RET${state.retornos[index].tituloFinanceiro.numeroTitulo}MULT`;
        movimento.movimentoFinanceiro.tipoMovimento = ETipoMovimentoFinanceiro.Recebimento;
        movimento.movimentoFinanceiro.codigoPlanoContaCategoria = state.retornos[index].categoriaMultaJuros;
        movimento.movimentoFinanceiro.descricao += ' JUROS/MULTA';
        movimento.movimentoFinanceiro.valor = state.retornos[index].valorJurosMulta;
      } else {
        movimento.referente = EMovimentoFinanceiroBaixaReferente.Taxa;
        movimento.movimentoFinanceiro.identificadorMovimento = `RET${state.retornos[index].tituloFinanceiro.numeroTitulo}TAXA`;
        movimento.movimentoFinanceiro.tipoMovimento = ETipoMovimentoFinanceiro.Pagamento;
        movimento.movimentoFinanceiro.codigoPlanoContaCategoria = state.retornos[index].categoriaTaxas;
        movimento.movimentoFinanceiro.descricao += ' TAXAS';
        movimento.movimentoFinanceiro.valor = state.retornos[index].valorTaxas;
      }
      movimento.movimentoFinanceiro.recursoOrigem = 'BAIXAS_CONTAS_RECEBER';
      movimento.movimentoFinanceiro.anexos = [];
      movimento.titulosBaixados = [] as IBaixaTitulosTituloFinanceiroBaixado[];
      return movimento;
    }

    function obterListaDatasMovimentos() {
      const datas = [] as string[];

      state.indexSelecionados.forEach((i) => {
        if (!datas.includes(state.retornos[i].dataCredito)) {
          datas.push(state.retornos[i].dataCredito);
        }
      });
      return datas;
    }

    function verificaValoresBaixaTitulo(i:number): boolean {
      const valorBaixa = UtilitarioFinanceiro.valorDecimal2Casas((state.retornos[i].valorTitulo + state.retornos[i].valorJurosMulta + state.retornos[i].valorOutrasDespesas + state.retornos[i].valorOutrosCreditos) - (state.retornos[i].valorAbatimento + state.retornos[i].valorDescontos + state.retornos[i].valorTaxas));
      state.retornos[i].valorPago = UtilitarioFinanceiro.valorDecimal2Casas(state.retornos[i].valorPago);
      if (valorBaixa !== state.retornos[i].valorPago) {
        apresentarMensagemAlerta(`Divergências no título: ${state.retornos[i].tituloFinanceiro.numeroTitulo}, a baixa está totalizando R$ ${UtilitarioMascara.mascararValor(valorBaixa)} e o valor pago no retorno é R$ ${UtilitarioMascara.mascararValor(state.retornos[i].valorPago)} verifique as informações de Juros/Multa, Descontos e Abatimentos.`);
        return false;
      }

      return true;
    }

    function obterBaixasPreenchidas() {
      const datas = obterListaDatasMovimentos();
      const baixas = [] as IBaixaTitulos[];
      let baixasValidas = true;
      datas.forEach((d) => {
        const baixa = {} as IBaixaTitulos;
        baixa.codigoEmpresa = props.empresa;
        baixa.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
        baixa.tipoTituloFinanceiro = ETipoTituloFinanceiro.Receber;
        baixa.dataBaixa = d;
        baixa.tipoAcrescimo = 2;
        baixa.tipoDesconto = 2;
        baixa.acrescimo = 0;
        baixa.descontoAdicional = 0;
        baixa.percentualJuros = 0;
        baixa.percentualMulta = 0;
        baixa.percentualDesconto = 0;
        baixa.totalAcrescimos = 0;
        baixa.totalSobraValores = 0;
        baixa.totalTitulosRepassados = 0;
        baixa.totalTitulos = 0;
        baixa.qtdTitulos = 0;
        baixa.totalBaixa = 0;
        baixa.totalJurosMulta = 0;
        baixa.totalDescontos = 0;
        baixa.totalAbatimentos = 0;
        baixa.totalTaxas = 0;
        baixa.titulosRepassados = [];
        baixa.titulosLancados = [];
        baixa.movimentosFinanceiros = [];

        state.indexSelecionados.forEach((i) => {
          if (d === state.retornos[i].dataCredito) {
            let detalhamentosTitulo:IDTOTituloFinanceiroDetalhamento[] = [];
            if (UtilitarioGeral.validaLista(state.detalhamentos)) {
              detalhamentosTitulo = state.detalhamentos.filter((c) => c.codigoTituloFinanceiro === state.retornos[i].tituloFinanceiro.codigo);
            }

            // Regras para lançar movimentos financeiros pelos Detalhamentos do Título
            if (UtilitarioGeral.validaLista(detalhamentosTitulo)) {
              detalhamentosTitulo = detalhamentosTitulo.sort((a, b) => a.ordem - b.ordem);
              const detalhamentosAbatimentos = detalhamentosTitulo.filter((detalhamentosTitulo) => detalhamentosTitulo.tipo === ETipoDetalhamentoTituloFinanceiro.Abatimento);
              if (UtilitarioGeral.validaLista(detalhamentosAbatimentos)) {
                baixa.totalAbatimentos = UtilitarioFinanceiro.valorDecimal2Casas(detalhamentosAbatimentos.reduce((valorAcumulado, detalhamento) => valorAcumulado + detalhamento.valor, 0));
                state.retornos[i].valorAbatimento = UtilitarioFinanceiro.valorDecimal2Casas(state.retornos[i].valorAbatimento);
                if (baixa.totalAbatimentos > 0) {
                  if (state.retornos[i].valorAbatimento === 0) {
                    apresentarMensagemAlerta(`O título: ${state.retornos[i].tituloFinanceiro.numeroTitulo} possuí abatimentos lançados no Contas a Receber porém não foi informado no retorno bancário.`);
                    baixasValidas = false;
                    return;
                  } if (baixa.totalAbatimentos !== state.retornos[i].valorAbatimento) {
                    apresentarMensagemAlerta(`O título: ${state.retornos[i].tituloFinanceiro.numeroTitulo} possuí ${UtilitarioMascara.mascararValor(baixa.totalAbatimentos)} abatimentos lançados no Contas a Receber porém foi informado R$ ${UtilitarioMascara.mascararValor(state.retornos[i].valorAbatimento)} de abatimento no retorno bancário.`);
                    baixasValidas = false;
                    return;
                  }
                }
              }

              const detalhamentosInformativos = detalhamentosTitulo.filter((detalhamentosTitulo) => detalhamentosTitulo.tipo === ETipoDetalhamentoTituloFinanceiro.Informativo);
              if (!UtilitarioGeral.validaLista(detalhamentosInformativos)) {
                apresentarMensagemAlerta(`O título: ${state.retornos[i].tituloFinanceiro.numeroTitulo} não possuí detalhamentos informativos.`);
                baixasValidas = false;
                return;
              }

              const valoresValidos = verificaValoresBaixaTitulo(i);
              if (!valoresValidos) {
                baixasValidas = false;
                return;
              }

              const maiorDetalhamentoInformativo = detalhamentosInformativos.reduce((max, detalhamento) => (detalhamento.valor > max.valor ? detalhamento : max));
              const indexMaiorDetalhamentoInformativo = detalhamentosTitulo.findIndex((detalhamento) => detalhamento === maiorDetalhamentoInformativo);
              for (let indexDetalhamento = 0; indexDetalhamento < detalhamentosTitulo.length; indexDetalhamento += 1) {
                baixa.movimentosFinanceiros.push(obterMovimentoFinanceiroPreenchidoPorDetalhamento(i, detalhamentosTitulo[indexDetalhamento], baixa.totalAbatimentos, (indexDetalhamento === indexMaiorDetalhamentoInformativo)));
              }
            } else {
              // Lança movimento Financeiro padrão para Baixa
              const valoresValidos = verificaValoresBaixaTitulo(i);
              if (!valoresValidos) {
                baixasValidas = false;
                return;
              }

              baixa.movimentosFinanceiros.push(obterMovimentoFinanceiroPreenchido(i));
            }

            if (state.retornos[i].valorJurosMulta > 0) {
              baixa.movimentosFinanceiros.push(obterMovimentoFinanceiroOutrosValores(i, 'M'));
            }
            if (state.retornos[i].valorDescontos > 0) {
              baixa.movimentosFinanceiros.push(obterMovimentoFinanceiroOutrosValores(i, 'D'));
            }
            if (state.retornos[i].valorTaxas > 0 && !state.retornos[i].ignorarTaxas) {
              baixa.movimentosFinanceiros.push(obterMovimentoFinanceiroOutrosValores(i, 'T'));
            }

            baixa.totalJurosMulta += state.retornos[i].valorJurosMulta;
            baixa.totalDescontos += state.retornos[i].valorDescontos;
            baixa.totalTitulos += state.retornos[i].valorTitulo;
            if (!state.retornos[i].ignorarTaxas) {
              baixa.totalTaxas += state.retornos[i].valorTaxas;
            }
            baixa.qtdTitulos += 1;
          }
        });
        baixa.totalBaixa = ((baixa.totalTitulos + baixa.totalJurosMulta) - (baixa.totalDescontos + baixa.totalTaxas + baixa.totalAbatimentos));
        baixas.push(baixa);
      });

      state.baixasValidas = baixasValidas;
      return baixas;
    }

    async function baixarSelecionados() {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      apresentarBarraProgresso();
      state.baixasValidas = false;
      const baixas = obterBaixasPreenchidas();
      if (state.baixasValidas) {
        retorno = await servicoBaixaTitulosReceber.lancarBaixasTitulos(baixas);
        ocultarBarraProgresso();

        if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
          state.indexSelecionados.forEach((i) => {
            state.retornos[i].status = EStatusRetornoBancario.Baixado;
          });
          state.indexSelecionados = [] as number[];
          apresentarMensagemSucesso('Títulos baixados com sucesso!');
          emit('confirmacao');
        } else {
          apresentarRetornoRequisicao(retorno);
        }
      } else {
        ocultarBarraProgresso();
      }
    }

    function confirmarBaixa() {
      if (state.indexSelecionados.length === 0) {
        apresentarMensagemAlerta('Nenhum retorno selecionado para fazer a baixa!');
        return;
      }

      let protestados = 0;
      state.indexSelecionados.forEach((i) => {
        if (state.retornos[i].status === EStatusRetornoBancario.Protestado || state.retornos[i].status === EStatusRetornoBancario.Negativado) {
          protestados += 1;
        }
      });

      let titulo = 'Realmente deseja baixar os títulos selecionados?';
      if (protestados > 0) {
        titulo = 'Existem títulos protestados ou negativados selecionados realmente deseja baixar os títulos?';
      }

      Modal.confirm({
        title: titulo,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        centered: true,
        onOk: () => {
          baixarSelecionados();
        },
      });
    }

    async function imprimir() {
      gradeBase.filtrosAplicados = [];
      apresentarBarraProgresso();
      const retornoRelatorio = await servicoBoletos.gerarRelatorioRetorno(props.empresa, state.nomeArquivo, state.conta.descricao, state.retornos);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
      ocultarBarraProgresso();
    }

    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        fecharSelecao();
      }
    };

    onMounted(() => {
      window.addEventListener('keydown', handleEsc);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('keydown', handleEsc);
    });

    watch(async () => modalBase.computedVisivel, async () => {
      limparTela(true);
      telaBase.carregando = true;
      if (modalBase.computedVisivel) {
        state.codigoConta = props.conta;
        await buscarConta();
        await obterParametros();
        verificarCache();
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      emit,
      telaBase,
      modalBase,
      gradeBase,
      storeSistema,
      EStatusRetornoBancario,
      EStatusTituloFinanceiro,
      UtilitarioData,
      UtilitarioMascara,
      buscarConta,
      filtrarOcorrencias,
      selecionarTodos,
      selecionarRetorno,
      alterarSelecao,
      fecharSelecao,
      selecionarArquivoUpload,
      uploadArquivoSelecionado,
      salvarCache,
      uploadArquivoArrastado,
      abrirBuscaTitulo,
      vincularTitulo,
      confirmarBaixa,
      criarMovimentoFinanceiro,
      vincularMovimentoCriado,
      imprimir,
      ignorarTaxas,
      recuperarCache,
      usarAbatimentoEZerarDesconto,
      apresentarSugestaoPossivelJurosMulta,
      usarValorJurosMultaSugestao,
      desvincularTitulo,
    };
  },
});
