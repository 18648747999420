import { reactive } from 'vue';
import { ESazonalidade } from '@/core/models/Enumeradores/ESazonalidade';
import { IDTOTituloFinanceiroCentroCusto } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroCentroCusto';
import { IDTOTituloFinanceiroRegistroAtividade } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroRegistroAtividade';
import { ITipoDocumentoFinanceiro } from '@/models/Entidades/Cadastros/Financeiro/ITipoDocumentoFinanceiro';
import {
  ITituloFinanceiro, ITituloFinanceiroAnexo, ITituloFinanceiroBoleto, ITituloFinanceiroCentroCusto, ITituloFinanceiroCheque, ITituloFinanceiroDetalhamento, ITituloFinanceiroDocumentoFiscal, ITituloFinanceiroMarcador, ITituloFinanceiroRepasse,
} from '@/models/Entidades/Financeiro/TitulosFinanceiros/ITituloFinanceiro';
import { ETipoEmissaoBoleto } from '@/models/Enumeradores/Financeiro/ETipoEmissaoBoleto';
import { ESituacaoCheque } from '@/models/Enumeradores/Financeiro/ESituacaoCheque';
import { EStatusTituloFinanceiro } from '@/models/Enumeradores/Financeiro/EStatusTituloFinanceiro';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import { IEventoCalendario } from '@/core/models/IEventoCalendario';
import { IBuscaAvancada } from '@/core/models/BuscaAvancada/IBuscaAvancada';
import { IFiltroGenericoAdicionado } from '@/core/models/BuscaAvancada/IFiltroGenericoAdicionado';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { ETipoDado } from '@/core/models/Enumeradores/ETipoDado';
import { EOperadorLogico } from '@/core/models/Enumeradores/EOperadorLogico';
import { ECondicao } from '@/core/models/Enumeradores/ECondicao';
import { IDTOTituloFinanceiroCalendario } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroCalendario';
import { EMeioPagamento } from '@/models/Enumeradores/Cadastros/Financeiro/EMeioPagamento';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ETipoDetalhamentoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoDetalhamentoTituloFinanceiro';
import { ESituacaoBoleto } from '@/models/Enumeradores/Financeiro/ESituacaoBoleto';
import UtilitarioFinanceiro from '@/core/utilitarios/UtilitarioFinanceiro';
import { ETipoTributo } from '@/models/Enumeradores/Financeiro/ETipoTributo';

export interface ITituloFinanceiroBase {
    titulo: ITituloFinanceiro;
    tipoDocumentoFinanceiro: ITipoDocumentoFinanceiro;
    centrosCusto: Array<IDTOTituloFinanceiroCentroCusto>;
    registrosAtividade: Array<IDTOTituloFinanceiroRegistroAtividade>;
    marcadores: Array<string>;
    repetir: boolean;
    sazonalidade: ESazonalidade;
    sazonalidadeCustomizada: number;
    repeticao: number;
    ultimoVencimento: string;
    classeStatus: string;
    status: string;
    editavel: boolean;
    tituloRepassado: boolean;
    carregandoBoleto: boolean;
    carregandoCheque: boolean;
    carregandoDocumentoFiscal: boolean;
    carregandoAnexos: boolean;
    carregandoDetalhamentos: boolean;
    carregandoCentrosCusto: boolean;
    carregandoHistorico: boolean;
    carregandoRepasse: boolean;
    dataAtual: string;
    dataCalendario: string;
    buscaRapida: string;
    totalDetalhado: number;
    titulos: Array<IDTOTituloFinanceiro>;
    titulosCalendario: Array<IDTOTituloFinanceiroCalendario>;
    eventos: Array<IEventoCalendario>;
    buscaAvancada: IBuscaAvancada;
    titulosBaixar: Array<IDTOTituloFinanceiro>;
    titulosExcluir: Array<IDTOTituloFinanceiro>;
    titulosEstornar: Array<IDTOTituloFinanceiro>;
    controlarAlcada: boolean;
    editavelBaixa: boolean;
}
export interface IRetornoTituloFinanceiroBase{
  tituloFinanceiroBase: ITituloFinanceiroBase;
  limparBoleto(): void;
  limparCheque(): void;
  limparStatus(): void;
  limparTela(tipoTituloFinanceiro:ETipoTituloFinanceiro): void;
  atualizarStatus(): void;
  tituloPendente(): boolean;
  obterSazonalidade():number;
  obterDescricaoSazonalidade(): string ;
  atualizarUltimoVencimento(): void;
  atualizarSaldo(): void;
  atualizarSaldoPeloDetalhamento(totalDetalhamentos:number): void;
  obterMensagemRepeticao():string;
  obterCentrosCusto():ITituloFinanceiroCentroCusto[];
  obterMarcadores():ITituloFinanceiroMarcador[];
  obterTotalDetalhado(): void;
  checkRepetir(): void;
  obterClasseStatus(status: string):string;
  criarEvento(Status: EStatusTituloFinanceiro, data: string, valor: number):IEventoCalendario;
  popularDadosEventos(): void;
  adicionaFiltroStatusPendente(): void;
  adicionaFiltroStatusBaixado(): void;
  adicionaFiltroVencimentoHoje(): void;
  adicionaFiltroVencimentoEsteMes(): void;
  adicionaFiltroVencimentoAtrasados(): void;
  adicionaFiltroBaixadosHoje(): void;
  adicionaFiltroBaixadosEsteMes(): void;
  adicionaFiltroBaixadosAtrasados(): void;
  obtemValorTitulo(titulo:IDTOTituloFinanceiro):number;
  verificarDetalhesPagamento():boolean;
  verificarTipoBoleto(meioPagamento: EMeioPagamento):boolean;
}

export function useTituloFinanceiroBase(props:any):IRetornoTituloFinanceiroBase {
  const tituloFinanceiroBase = reactive({
    titulo: {} as ITituloFinanceiro,
    tipoDocumentoFinanceiro: {} as ITipoDocumentoFinanceiro,
    centrosCusto: [] as IDTOTituloFinanceiroCentroCusto[],
    registrosAtividade: [] as IDTOTituloFinanceiroRegistroAtividade[],
    marcadores: [] as string[],
    repetir: false,
    sazonalidade: ESazonalidade.mensal,
    sazonalidadeCustomizada: 30,
    repeticao: 0,
    ultimoVencimento: '',
    classeStatus: '',
    status: '',
    editavel: true,
    tituloRepassado: false,
    carregandoBoleto: false,
    carregandoCheque: false,
    carregandoDocumentoFiscal: false,
    carregandoAnexos: false,
    carregandoDetalhamentos: false,
    carregandoCentrosCusto: false,
    carregandoHistorico: false,
    carregandoRepasse: false,
    dataAtual: '',
    dataCalendario: '',
    buscaRapida: '',
    totalDetalhado: 0,
    titulos: [] as IDTOTituloFinanceiro[],
    titulosCalendario: [] as IDTOTituloFinanceiroCalendario[],
    eventos: [] as IEventoCalendario[],
    buscaAvancada: {} as IBuscaAvancada,
    titulosBaixar: [] as IDTOTituloFinanceiro[],
    titulosExcluir: [] as IDTOTituloFinanceiro[],
    titulosEstornar: [] as IDTOTituloFinanceiro[],
    controlarAlcada: false,
    editavelBaixa: true,
  });

  function limparBoleto() {
    tituloFinanceiroBase.titulo.boleto = {} as ITituloFinanceiroBoleto;
    tituloFinanceiroBase.titulo.boleto.codigo = 0;
    tituloFinanceiroBase.titulo.boleto.codigoTituloFinanceiro = props.operacao.codigoRegistro;
    tituloFinanceiroBase.titulo.boleto.tipoEmissaoBoleto = ETipoEmissaoBoleto.Terceiros;
    tituloFinanceiroBase.titulo.boleto.dataEmissao = '';
    tituloFinanceiroBase.titulo.boleto.dataProcessamento = '';
    tituloFinanceiroBase.titulo.boleto.situacao = ESituacaoBoleto.NaoEmitido;
    tituloFinanceiroBase.titulo.boleto.chaveBoleto = '';
  }

  function limparCheque() {
    tituloFinanceiroBase.titulo.cheque = {} as ITituloFinanceiroCheque;
    tituloFinanceiroBase.titulo.cheque.codigo = 0;
    tituloFinanceiroBase.titulo.cheque.codigoTituloFinanceiro = props.operacao.codigoRegistro;
    tituloFinanceiroBase.titulo.cheque.situacao = ESituacaoCheque.PreDatado;
    tituloFinanceiroBase.titulo.cheque.terceiros = false;
  }

  function limparStatus() {
    tituloFinanceiroBase.titulo.status = EStatusTituloFinanceiro.Pendente;
    tituloFinanceiroBase.classeStatus = '';
    tituloFinanceiroBase.status = '';
  }

  async function limparTela(tipoTituloFinanceiro:ETipoTituloFinanceiro) {
    tituloFinanceiroBase.titulo = {} as ITituloFinanceiro;
    tituloFinanceiroBase.titulo.codigo = 0;
    tituloFinanceiroBase.titulo.tipo = tipoTituloFinanceiro;
    tituloFinanceiroBase.titulo.tipoTributo = ETipoTributo.DARF;
    tituloFinanceiroBase.titulo.dataCompetencia = UtilitarioData.obterDataAtual();
    tituloFinanceiroBase.titulo.documentoFiscal = {} as ITituloFinanceiroDocumentoFiscal;
    tituloFinanceiroBase.tipoDocumentoFinanceiro = {} as ITipoDocumentoFinanceiro;
    tituloFinanceiroBase.tipoDocumentoFinanceiro.meioPagamento = EMeioPagamento.Dinheiro;
    limparBoleto();
    limparCheque();
    limparStatus();
    tituloFinanceiroBase.titulo.codigoEmpresa = props.operacao.empresaSelecionada;
    tituloFinanceiroBase.titulo.centrosCusto = [] as ITituloFinanceiroCentroCusto[];
    tituloFinanceiroBase.titulo.detalhamentos = [] as ITituloFinanceiroDetalhamento[];
    tituloFinanceiroBase.titulo.anexos = [] as ITituloFinanceiroAnexo[];
    tituloFinanceiroBase.centrosCusto = [] as IDTOTituloFinanceiroCentroCusto[];
    tituloFinanceiroBase.registrosAtividade = [] as IDTOTituloFinanceiroRegistroAtividade[];
    tituloFinanceiroBase.marcadores = [] as string[];
    tituloFinanceiroBase.titulo.repasse = {} as ITituloFinanceiroRepasse;
    tituloFinanceiroBase.repetir = false;
    tituloFinanceiroBase.editavel = true;
    tituloFinanceiroBase.tituloRepassado = false;
    tituloFinanceiroBase.repeticao = 0;
  }

  function atualizarStatus() {
    switch (tituloFinanceiroBase.titulo.status) {
      case EStatusTituloFinanceiro.Pendente:
        tituloFinanceiroBase.classeStatus = 'pendente';
        tituloFinanceiroBase.status = 'Pendente';
        tituloFinanceiroBase.editavel = true;
        tituloFinanceiroBase.editavelBaixa = true;
        break;
      case EStatusTituloFinanceiro.Baixado:
        tituloFinanceiroBase.classeStatus = 'baixado';
        tituloFinanceiroBase.status = 'Baixado';
        tituloFinanceiroBase.editavel = false;
        tituloFinanceiroBase.editavelBaixa = true;
        break;
      case EStatusTituloFinanceiro.BaixadoParcialmente:
        tituloFinanceiroBase.classeStatus = 'baixado-parcialmente';
        tituloFinanceiroBase.status = 'Baixado Parcialmente';
        tituloFinanceiroBase.editavel = false;
        tituloFinanceiroBase.editavelBaixa = true;
        break;
      case EStatusTituloFinanceiro.BaixadoCartao:
        tituloFinanceiroBase.classeStatus = 'baixado-cartao';
        tituloFinanceiroBase.status = 'Baixado Cartão';
        tituloFinanceiroBase.editavel = false;
        tituloFinanceiroBase.editavelBaixa = false;
        break;
      case EStatusTituloFinanceiro.Renegociado:
        tituloFinanceiroBase.classeStatus = 'renegociado';
        tituloFinanceiroBase.status = 'Renegociado';
        tituloFinanceiroBase.editavel = false;
        tituloFinanceiroBase.editavelBaixa = false;
        break;
      case EStatusTituloFinanceiro.Excluido:
        tituloFinanceiroBase.classeStatus = 'excluido';
        tituloFinanceiroBase.status = 'Excluído';
        tituloFinanceiroBase.editavel = false;
        tituloFinanceiroBase.editavelBaixa = false;
        break;
      case EStatusTituloFinanceiro.AprovacaoPendente:
        tituloFinanceiroBase.classeStatus = 'aprovacao-pendente';
        tituloFinanceiroBase.status = 'Aprovação Pendente';
        tituloFinanceiroBase.editavel = true;
        tituloFinanceiroBase.editavelBaixa = true;
        break;
      case EStatusTituloFinanceiro.Reprovado:
        tituloFinanceiroBase.classeStatus = 'reprovado';
        tituloFinanceiroBase.status = 'Reprovado';
        tituloFinanceiroBase.editavel = true;
        tituloFinanceiroBase.editavelBaixa = true;
        break;
      default:
        tituloFinanceiroBase.classeStatus = '';
        tituloFinanceiroBase.status = '';
        tituloFinanceiroBase.editavel = true;
        tituloFinanceiroBase.editavelBaixa = true;
    }
  }

  function tituloPendente(): boolean {
    if (tituloFinanceiroBase.titulo.status === EStatusTituloFinanceiro.Pendente || tituloFinanceiroBase.titulo.status === EStatusTituloFinanceiro.BaixadoParcialmente) {
      return true;
    }
    return false;
  }

  function obterSazonalidade():number {
    switch (tituloFinanceiroBase.sazonalidade) {
      case ESazonalidade.semanal:
        return 7;
      case ESazonalidade.quinzenal:
        return 15;
      case ESazonalidade.mensal:
        return 30;
      case ESazonalidade.bimestral:
        return 60;
      case ESazonalidade.trimestral:
        return 90;
      case ESazonalidade.semestral:
        return 180;
      case ESazonalidade.anual:
        return 360;
      default:
        return tituloFinanceiroBase.sazonalidadeCustomizada;
    }
  }

  function obterDescricaoSazonalidade(): string {
    switch (tituloFinanceiroBase.sazonalidade) {
      case ESazonalidade.semanal:
        return 'semanas';
      case ESazonalidade.quinzenal:
        return 'quinzenas';
      case ESazonalidade.mensal:
        return 'meses';
      case ESazonalidade.bimestral:
        return 'bimestres';
      case ESazonalidade.trimestral:
        return 'trimestres';
      case ESazonalidade.semestral:
        return 'semestres';
      case ESazonalidade.anual:
        return 'anos';
      default:
        return 'vezes';
    }
  }

  function obterTotalDetalhado() {
    if (UtilitarioGeral.validaLista(tituloFinanceiroBase.titulo.detalhamentos)) {
      let total = 0;
      tituloFinanceiroBase.titulo.detalhamentos.forEach((d) => {
        if (d.tipo === ETipoDetalhamentoTituloFinanceiro.Informativo) {
          total += d.valor;
        } else {
          total -= d.valor;
        }
      });
      tituloFinanceiroBase.totalDetalhado = total;
    }
  }

  function atualizarUltimoVencimento() {
    if (tituloFinanceiroBase.titulo.dataVencimento === undefined) {
      tituloFinanceiroBase.ultimoVencimento = '';
      return;
    }
    const sazonalidade = obterSazonalidade();
    let ultimaData = UtilitarioData.converterDataParaDayJs(tituloFinanceiroBase.titulo.dataVencimento);
    for (let contador = 1; contador <= tituloFinanceiroBase.repeticao; contador += 1) {
      switch (sazonalidade) {
        case 7:
          ultimaData = UtilitarioData.adicionarDiaNaDataDayJs(ultimaData, 7);
          break;
        case 15:
          ultimaData = UtilitarioData.adicionarDiaNaDataDayJs(ultimaData, 15);
          break;
        case 30:
          ultimaData = UtilitarioData.adicionarMesNaDataDayJs(ultimaData, 1);
          break;
        case 60:
          ultimaData = UtilitarioData.adicionarMesNaDataDayJs(ultimaData, 2);
          break;
        case 90:
          ultimaData = UtilitarioData.adicionarMesNaDataDayJs(ultimaData, 3);
          break;
        case 180:
          ultimaData = UtilitarioData.adicionarMesNaDataDayJs(ultimaData, 4);
          break;
        case 365:
          ultimaData = UtilitarioData.adicionarAnoNaDataDayJs(ultimaData, 1);
          break;
        default:
          break;
      }
    }
    tituloFinanceiroBase.ultimoVencimento = UtilitarioData.aplicaFormatoData(ultimaData);
  }

  function atualizarSaldo() {
    if (tituloFinanceiroBase.titulo.status === EStatusTituloFinanceiro.Pendente && !UtilitarioGeral.validaLista(tituloFinanceiroBase.titulo.detalhamentos)) {
      tituloFinanceiroBase.titulo.saldo = tituloFinanceiroBase.titulo.valorTotal;
    }
  }

  function atualizarSaldoPeloDetalhamento(totalDetalhamentos: number) {
    // ATualiza saldo do título automaticamente somente em títulos novos
    if (tituloFinanceiroBase.titulo.codigo === 0 && tituloFinanceiroBase.titulo.status === EStatusTituloFinanceiro.Pendente) {
      if (totalDetalhamentos > 0) {
        tituloFinanceiroBase.titulo.saldo = totalDetalhamentos;
      } else {
        tituloFinanceiroBase.titulo.saldo = tituloFinanceiroBase.titulo.valorTotal;
      }
    }
  }

  function obterMensagemRepeticao():string {
    atualizarUltimoVencimento();
    const valorTotal = UtilitarioMascara.mascararValor(tituloFinanceiroBase.titulo.valorTotal + (tituloFinanceiroBase.repeticao * tituloFinanceiroBase.titulo.valorTotal), 2, true);
    if (tituloFinanceiroBase.repeticao === 1) {
      return `Será criado 1 título adicional, totalizando ${valorTotal}.\nO último vencimento será: ${tituloFinanceiroBase.ultimoVencimento}.`;
    }
    return `Serão criados ${tituloFinanceiroBase.repeticao} títulos adicionais, totalizando ${valorTotal}.\nO último vencimento será: ${tituloFinanceiroBase.ultimoVencimento}.`;
  }

  function obterCentrosCusto():ITituloFinanceiroCentroCusto[] {
    const centrosCusto = [] as ITituloFinanceiroCentroCusto[];

    tituloFinanceiroBase.centrosCusto.forEach((c) => {
      const centroCusto = {} as ITituloFinanceiroCentroCusto;
      centroCusto.codigo = c.codigo;
      centroCusto.codigoCentroCusto = c.codigoCentroCusto;
      centroCusto.codigoTituloFinanceiro = c.codigoTituloFinanceiro;
      centroCusto.proporcao = c.proporcao;
      centroCusto.ordem = c.ordem;
      centrosCusto.push(centroCusto);
    });
    return centrosCusto;
  }

  function obterMarcadores():ITituloFinanceiroMarcador[] {
    const marcadores = [] as ITituloFinanceiroMarcador[];

    tituloFinanceiroBase.marcadores.forEach((m) => {
      const marcador = {} as ITituloFinanceiroMarcador;
      marcador.codigoTituloFinanceiro = tituloFinanceiroBase.titulo.codigo;
      marcador.marcador = m;
      marcador.ordem = marcadores.length + 1;
      marcadores.push(marcador);
    });
    return marcadores;
  }

  function checkRepetir() {
    if (tituloFinanceiroBase.repetir) {
      tituloFinanceiroBase.repeticao = 1;
      tituloFinanceiroBase.titulo.parcela = 1;
    } else {
      tituloFinanceiroBase.repeticao = 0;
      tituloFinanceiroBase.titulo.parcela = 0;
    }
  }

  function obterClasseStatus(status: string):string {
    switch (status) {
      case 'Pendente':
        return 'pendente';
      case 'Baixado':
        return 'baixado';
      case 'Baixado Parcialmente':
        return 'baixado-parcialmente';
      case 'Baixado pelo Cartão':
        return 'baixado-cartao';
      case 'Renegociado':
        return 'renegociado';
      case 'Aprovação Pendente':
        return 'aprovacao-pendente';
      case 'Reprovado':
        return 'reprovado';
      default:
        return 'excluido';
    }
  }

  function criarEvento(Status: EStatusTituloFinanceiro, data: string, valor: number):IEventoCalendario {
    const evento = {} as IEventoCalendario;
    evento.data = UtilitarioData.converterDataJson(data);
    switch (Status) {
      case EStatusTituloFinanceiro.Pendente:
        evento.cor = 'rgb(219, 216, 14)';
        evento.titulo = `Título(s) pendente(s): ${UtilitarioMascara.mascararValor(valor, 2)}`;
        break;
      case EStatusTituloFinanceiro.Baixado:
        evento.cor = '#11a818';
        evento.titulo = `Título(s) baixado(s): ${UtilitarioMascara.mascararValor(valor, 2)}`;
        break;
      case EStatusTituloFinanceiro.BaixadoParcialmente:
        evento.cor = 'rgb(127, 13, 172)';
        evento.titulo = `Título(s) baixado(s) parcialmente: ${UtilitarioMascara.mascararValor(valor, 2)}`;
        break;
      case EStatusTituloFinanceiro.BaixadoCartao:
        evento.cor = '#1bce66';
        evento.titulo = `Título(s) baixado(s) por cartão: ${UtilitarioMascara.mascararValor(valor, 2)}`;
        break;
      case EStatusTituloFinanceiro.Renegociado:
        evento.cor = 'rgb(32, 59, 209)';
        evento.titulo = `Título(s) renegociado(s): ${UtilitarioMascara.mascararValor(valor, 2)}`;
        break;
      default:
        evento.cor = '#e61717';
        evento.titulo = `Título(s) atrasado(s): ${UtilitarioMascara.mascararValor(valor, 2)}`;
        break;
    }
    return evento;
  }

  function popularDadosEventos() {
    tituloFinanceiroBase.eventos = [] as IEventoCalendario[];
    tituloFinanceiroBase.titulosCalendario.forEach((t) => {
      if ((t.status === EStatusTituloFinanceiro.Pendente || t.status === EStatusTituloFinanceiro.BaixadoParcialmente) && (UtilitarioData.verificaDiferencaDiasEntreDatas(t.data, UtilitarioData.obterDataAtual()) > 0)) {
        const eventoIndex = tituloFinanceiroBase.eventos.findIndex((e) => UtilitarioData.aplicaFormatoData(e.data) === UtilitarioData.aplicaFormatoData(t.data) && e.titulo.includes('atrasado'));
        if (eventoIndex >= 0) {
          let valor = tituloFinanceiroBase.eventos[eventoIndex].titulo.split(': ')[1];
          valor = valor.replace('.', '').replace(',', '.');
          tituloFinanceiroBase.eventos[eventoIndex] = criarEvento(EStatusTituloFinanceiro.Vencido, t.data, Number(valor) + t.valor);
        } else {
          tituloFinanceiroBase.eventos.push(criarEvento(EStatusTituloFinanceiro.Vencido, t.data, t.valor));
        }
      } else if (t.status === EStatusTituloFinanceiro.Pendente) {
        const eventoIndex = tituloFinanceiroBase.eventos.findIndex((e) => UtilitarioData.aplicaFormatoData(e.data) === UtilitarioData.aplicaFormatoData(t.data) && e.titulo.includes('pendente'));
        if (eventoIndex >= 0) {
          let valor = tituloFinanceiroBase.eventos[eventoIndex].titulo.split(': ')[1];
          valor = valor.replace('.', '').replace(',', '.');
          tituloFinanceiroBase.eventos[eventoIndex] = criarEvento(EStatusTituloFinanceiro.Pendente, t.data, Number(valor) + t.valor);
        } else {
          tituloFinanceiroBase.eventos.push(criarEvento(EStatusTituloFinanceiro.Pendente, t.data, t.valor));
        }
      } else if (t.status === EStatusTituloFinanceiro.BaixadoParcialmente) {
        const eventoIndex = tituloFinanceiroBase.eventos.findIndex((e) => UtilitarioData.aplicaFormatoData(e.data) === UtilitarioData.aplicaFormatoData(t.data) && e.titulo.includes('baixado(s) parcialmente'));
        if (eventoIndex >= 0) {
          let valor = tituloFinanceiroBase.eventos[eventoIndex].titulo.split(': ')[1];
          valor = valor.replace('.', '').replace(',', '.');
          tituloFinanceiroBase.eventos[eventoIndex] = criarEvento(EStatusTituloFinanceiro.BaixadoParcialmente, t.data, Number(valor) + t.valor);
        } else {
          tituloFinanceiroBase.eventos.push(criarEvento(EStatusTituloFinanceiro.BaixadoParcialmente, t.data, t.valor));
        }
      } else if (t.status === EStatusTituloFinanceiro.Renegociado) {
        const eventoIndex = tituloFinanceiroBase.eventos.findIndex((e) => UtilitarioData.aplicaFormatoData(e.data) === UtilitarioData.aplicaFormatoData(t.data) && e.titulo.includes('renegociado'));
        if (eventoIndex >= 0) {
          let valor = tituloFinanceiroBase.eventos[eventoIndex].titulo.split(': ')[1];
          valor = valor.replace('.', '').replace(',', '.');
          tituloFinanceiroBase.eventos[eventoIndex] = criarEvento(EStatusTituloFinanceiro.Renegociado, t.data, Number(valor) + t.valor);
        } else {
          tituloFinanceiroBase.eventos.push(criarEvento(EStatusTituloFinanceiro.Renegociado, t.data, t.valor));
        }
      } else if (t.status === EStatusTituloFinanceiro.BaixadoCartao) {
        const eventoIndex = tituloFinanceiroBase.eventos.findIndex((e) => UtilitarioData.aplicaFormatoData(e.data) === UtilitarioData.aplicaFormatoData(t.data) && e.titulo.includes('cartão'));
        if (eventoIndex >= 0) {
          let valor = tituloFinanceiroBase.eventos[eventoIndex].titulo.split(': ')[1];
          valor = valor.replace('.', '').replace(',', '.');
          tituloFinanceiroBase.eventos[eventoIndex] = criarEvento(EStatusTituloFinanceiro.BaixadoCartao, t.data, Number(valor) + t.valor);
        } else {
          tituloFinanceiroBase.eventos.push(criarEvento(EStatusTituloFinanceiro.BaixadoCartao, t.data, t.valor));
        }
      } else if (t.status === EStatusTituloFinanceiro.Baixado) {
        const eventoIndex = tituloFinanceiroBase.eventos.findIndex((e) => UtilitarioData.aplicaFormatoData(e.data) === UtilitarioData.aplicaFormatoData(t.data) && e.titulo.includes('baixado(s):'));
        if (eventoIndex >= 0) {
          let valor = tituloFinanceiroBase.eventos[eventoIndex].titulo.split(': ')[1];
          valor = valor.replace('.', '').replace(',', '.');
          tituloFinanceiroBase.eventos[eventoIndex] = criarEvento(EStatusTituloFinanceiro.Baixado, t.data, Number(valor) + t.valor);
        } else {
          tituloFinanceiroBase.eventos.push(criarEvento(EStatusTituloFinanceiro.Baixado, t.data, t.valor));
        }
      }
    });
  }

  function adicionaFiltroStatusPendente() {
    const filtroStatusPendente = {} as IFiltroGenericoAdicionado;
    tituloFinanceiroBase.buscaAvancada.chaveFiltrosAdicionados += 1;
    filtroStatusPendente.codigo = tituloFinanceiroBase.buscaAvancada.chaveFiltrosAdicionados;
    filtroStatusPendente.filtro = {} as IFiltroGenerico;
    filtroStatusPendente.filtro.identificador = 'Status';
    filtroStatusPendente.apresentacao = 'Status';
    filtroStatusPendente.filtro.tipoDado = ETipoDado.Inteiro;
    filtroStatusPendente.filtro.operador = EOperadorLogico.E;
    filtroStatusPendente.filtro.condicao = ECondicao.EstaEntre;
    if (tituloFinanceiroBase.controlarAlcada) {
      filtroStatusPendente.filtro.valores = [String(EStatusTituloFinanceiro.Pendente), String(EStatusTituloFinanceiro.BaixadoParcialmente), String(EStatusTituloFinanceiro.AprovacaoPendente), String(EStatusTituloFinanceiro.Reprovado)];
      filtroStatusPendente.detalhes = 'qualquer um desses: Pendente, Baixado Parcialmente, Aprovação Pendente, Reprovado';
    } else {
      filtroStatusPendente.filtro.valores = [String(EStatusTituloFinanceiro.Pendente), String(EStatusTituloFinanceiro.BaixadoParcialmente)];
      filtroStatusPendente.detalhes = 'qualquer um desses: Pendente, Baixado Parcialmente';
    }
    tituloFinanceiroBase.buscaAvancada.filtrosAdicionados.push(filtroStatusPendente);
  }

  function adicionaFiltroStatusBaixado() {
    const filtroStatusBaixado = {} as IFiltroGenericoAdicionado;
    tituloFinanceiroBase.buscaAvancada.chaveFiltrosAdicionados += 1;
    filtroStatusBaixado.codigo = tituloFinanceiroBase.buscaAvancada.chaveFiltrosAdicionados;
    filtroStatusBaixado.filtro = {} as IFiltroGenerico;
    filtroStatusBaixado.filtro.identificador = 'Status';
    filtroStatusBaixado.apresentacao = 'Status';
    filtroStatusBaixado.filtro.tipoDado = ETipoDado.Inteiro;
    filtroStatusBaixado.filtro.operador = EOperadorLogico.E;
    filtroStatusBaixado.filtro.condicao = ECondicao.EstaEntre;
    filtroStatusBaixado.filtro.valores = [String(EStatusTituloFinanceiro.Baixado), String(EStatusTituloFinanceiro.BaixadoParcialmente), String(EStatusTituloFinanceiro.BaixadoCartao), String(EStatusTituloFinanceiro.BaixadoCredito)];
    filtroStatusBaixado.detalhes = 'qualquer um desses: Baixado, Baixado Parcialmente, Baixado Cartão, Baixado Crédito';
    tituloFinanceiroBase.buscaAvancada.filtrosAdicionados.push(filtroStatusBaixado);
  }

  function adicionaFiltroVencimentoHoje() {
    adicionaFiltroStatusPendente();
    const filtroVencimentoHoje = {} as IFiltroGenericoAdicionado;
    tituloFinanceiroBase.buscaAvancada.chaveFiltrosAdicionados += 1;
    filtroVencimentoHoje.codigo = tituloFinanceiroBase.buscaAvancada.chaveFiltrosAdicionados;
    filtroVencimentoHoje.filtro = {} as IFiltroGenerico;
    filtroVencimentoHoje.filtro.identificador = 'DataVencimento';
    filtroVencimentoHoje.apresentacao = 'Data do Vencimento';
    filtroVencimentoHoje.filtro.tipoDado = ETipoDado.Data;
    filtroVencimentoHoje.filtro.operador = EOperadorLogico.E;
    filtroVencimentoHoje.filtro.condicao = ECondicao.Igual;
    filtroVencimentoHoje.filtro.valores = [tituloFinanceiroBase.dataAtual];
    filtroVencimentoHoje.detalhes = `igual ${UtilitarioData.aplicaFormatoData(tituloFinanceiroBase.dataAtual)}`;
    tituloFinanceiroBase.buscaAvancada.filtrosAdicionados.push(filtroVencimentoHoje);
  }

  function adicionaFiltroVencimentoEsteMes() {
    adicionaFiltroStatusPendente();
    const filtroVencimentoEsteMes = {} as IFiltroGenericoAdicionado;
    tituloFinanceiroBase.buscaAvancada.chaveFiltrosAdicionados += 1;
    filtroVencimentoEsteMes.codigo = tituloFinanceiroBase.buscaAvancada.chaveFiltrosAdicionados;
    filtroVencimentoEsteMes.filtro = {} as IFiltroGenerico;
    filtroVencimentoEsteMes.filtro.identificador = 'DataVencimento';
    filtroVencimentoEsteMes.apresentacao = 'Data do Vencimento';
    filtroVencimentoEsteMes.filtro.tipoDado = ETipoDado.Data;
    filtroVencimentoEsteMes.filtro.operador = EOperadorLogico.E;
    filtroVencimentoEsteMes.filtro.condicao = ECondicao.Entre;
    const primeiroDiaMes = UtilitarioData.obterPrimeiroDiaMes(tituloFinanceiroBase.dataAtual);
    const ultimoDiaMes = UtilitarioData.obterUltimoDiaMes(tituloFinanceiroBase.dataAtual);
    filtroVencimentoEsteMes.filtro.valores = [primeiroDiaMes, ultimoDiaMes];
    filtroVencimentoEsteMes.detalhes = `Entre : ${UtilitarioData.aplicaFormatoData(primeiroDiaMes)} e ${UtilitarioData.aplicaFormatoData(ultimoDiaMes)}`;
    tituloFinanceiroBase.buscaAvancada.filtrosAdicionados.push(filtroVencimentoEsteMes);
  }

  function adicionaFiltroVencimentoAtrasados() {
    adicionaFiltroStatusPendente();
    const filtroVencimentoAtrasados = {} as IFiltroGenericoAdicionado;
    tituloFinanceiroBase.buscaAvancada.chaveFiltrosAdicionados += 1;
    filtroVencimentoAtrasados.codigo = tituloFinanceiroBase.buscaAvancada.chaveFiltrosAdicionados;
    filtroVencimentoAtrasados.filtro = {} as IFiltroGenerico;
    filtroVencimentoAtrasados.filtro.identificador = 'DataVencimento';
    filtroVencimentoAtrasados.apresentacao = 'Data do Vencimento';
    filtroVencimentoAtrasados.filtro.tipoDado = ETipoDado.Data;
    filtroVencimentoAtrasados.filtro.operador = EOperadorLogico.E;
    filtroVencimentoAtrasados.filtro.condicao = ECondicao.Menor;
    filtroVencimentoAtrasados.filtro.valores = [tituloFinanceiroBase.dataAtual];
    filtroVencimentoAtrasados.detalhes = `menor que: ${UtilitarioData.aplicaFormatoData(tituloFinanceiroBase.dataAtual)}`;
    tituloFinanceiroBase.buscaAvancada.filtrosAdicionados.push(filtroVencimentoAtrasados);
  }

  function adicionaFiltroBaixadosHoje() {
    adicionaFiltroStatusBaixado();
    const filtroBaixadosHoje = {} as IFiltroGenericoAdicionado;
    tituloFinanceiroBase.buscaAvancada.chaveFiltrosAdicionados += 1;
    filtroBaixadosHoje.codigo = tituloFinanceiroBase.buscaAvancada.chaveFiltrosAdicionados;
    filtroBaixadosHoje.filtro = {} as IFiltroGenerico;
    filtroBaixadosHoje.filtro.identificador = 'DataBaixa';
    filtroBaixadosHoje.apresentacao = 'Data da Baixa';
    filtroBaixadosHoje.filtro.tipoDado = ETipoDado.Data;
    filtroBaixadosHoje.filtro.operador = EOperadorLogico.E;
    filtroBaixadosHoje.filtro.condicao = ECondicao.Igual;
    filtroBaixadosHoje.filtro.valores = [tituloFinanceiroBase.dataAtual];
    filtroBaixadosHoje.detalhes = `igual ${UtilitarioData.aplicaFormatoData(tituloFinanceiroBase.dataAtual)}`;
    tituloFinanceiroBase.buscaAvancada.filtrosAdicionados.push(filtroBaixadosHoje);
  }

  function adicionaFiltroBaixadosEsteMes() {
    adicionaFiltroStatusBaixado();
    const filtroBaixadosEsteMes = {} as IFiltroGenericoAdicionado;
    tituloFinanceiroBase.buscaAvancada.chaveFiltrosAdicionados += 1;
    filtroBaixadosEsteMes.codigo = tituloFinanceiroBase.buscaAvancada.chaveFiltrosAdicionados;
    filtroBaixadosEsteMes.filtro = {} as IFiltroGenerico;
    filtroBaixadosEsteMes.filtro.identificador = 'DataBaixa';
    filtroBaixadosEsteMes.apresentacao = 'Data da Baixa';
    filtroBaixadosEsteMes.filtro.tipoDado = ETipoDado.Data;
    filtroBaixadosEsteMes.filtro.operador = EOperadorLogico.E;
    filtroBaixadosEsteMes.filtro.condicao = ECondicao.Entre;
    const primeiroDiaMes = UtilitarioData.obterPrimeiroDiaMes(tituloFinanceiroBase.dataAtual);
    const ultimoDiaMes = UtilitarioData.obterUltimoDiaMes(tituloFinanceiroBase.dataAtual);
    filtroBaixadosEsteMes.filtro.valores = [primeiroDiaMes, ultimoDiaMes];
    filtroBaixadosEsteMes.detalhes = `entre : ${UtilitarioData.aplicaFormatoData(primeiroDiaMes)} e ${UtilitarioData.aplicaFormatoData(ultimoDiaMes)}`;
    tituloFinanceiroBase.buscaAvancada.filtrosAdicionados.push(filtroBaixadosEsteMes);
  }

  function adicionaFiltroBaixadosAtrasados() {
    adicionaFiltroStatusBaixado();

    const filtroBaixadosEsteMes = {} as IFiltroGenericoAdicionado;
    tituloFinanceiroBase.buscaAvancada.chaveFiltrosAdicionados += 1;
    filtroBaixadosEsteMes.codigo = tituloFinanceiroBase.buscaAvancada.chaveFiltrosAdicionados;
    filtroBaixadosEsteMes.filtro = {} as IFiltroGenerico;
    filtroBaixadosEsteMes.filtro.identificador = 'DataBaixa';
    filtroBaixadosEsteMes.apresentacao = 'Data da Baixa';
    filtroBaixadosEsteMes.filtro.tipoDado = ETipoDado.Data;
    filtroBaixadosEsteMes.filtro.operador = EOperadorLogico.E;
    filtroBaixadosEsteMes.filtro.condicao = ECondicao.Entre;
    const primeiroDiaMes = UtilitarioData.obterPrimeiroDiaMes(tituloFinanceiroBase.dataAtual);
    const ultimoDiaMes = UtilitarioData.obterUltimoDiaMes(tituloFinanceiroBase.dataAtual);
    filtroBaixadosEsteMes.filtro.valores = [primeiroDiaMes, ultimoDiaMes];
    filtroBaixadosEsteMes.detalhes = `entre : ${UtilitarioData.aplicaFormatoData(primeiroDiaMes)} e ${UtilitarioData.aplicaFormatoData(ultimoDiaMes)}`;
    tituloFinanceiroBase.buscaAvancada.filtrosAdicionados.push(filtroBaixadosEsteMes);

    const filtroBaixadosAtrasados = {} as IFiltroGenericoAdicionado;
    tituloFinanceiroBase.buscaAvancada.chaveFiltrosAdicionados += 1;
    filtroBaixadosAtrasados.codigo = tituloFinanceiroBase.buscaAvancada.chaveFiltrosAdicionados;
    filtroBaixadosAtrasados.filtro = {} as IFiltroGenerico;
    filtroBaixadosAtrasados.filtro.identificador = 'DataVencimento';
    filtroBaixadosAtrasados.apresentacao = 'Data do Vencimento';
    filtroBaixadosAtrasados.filtro.tipoDado = ETipoDado.Data;
    filtroBaixadosAtrasados.filtro.operador = EOperadorLogico.E;
    filtroBaixadosAtrasados.filtro.condicao = ECondicao.Menor;
    filtroBaixadosAtrasados.filtro.valores = [primeiroDiaMes];
    filtroBaixadosAtrasados.detalhes = `menor que: ${UtilitarioData.aplicaFormatoData(primeiroDiaMes)}`;
    tituloFinanceiroBase.buscaAvancada.filtrosAdicionados.push(filtroBaixadosAtrasados);
  }

  function obtemValorTitulo(titulo:IDTOTituloFinanceiro):number {
    if (titulo.status === EStatusTituloFinanceiro.Pendente || titulo.status === EStatusTituloFinanceiro.BaixadoParcialmente) {
      return titulo.saldo;
    }

    return titulo.valorTotal;
  }

  function verificarDetalhesPagamento():boolean {
    if (tituloFinanceiroBase.titulo.detalhamentos.length === 0) {
      return true;
    }

    let totalDetalhado = 0;

    tituloFinanceiroBase.titulo.detalhamentos.forEach((d) => {
      if (d.tipo === ETipoDetalhamentoTituloFinanceiro.Informativo) {
        totalDetalhado += UtilitarioFinanceiro.valorDecimal2Casas(d.valor);
      } else if (d.tipo === ETipoDetalhamentoTituloFinanceiro.Abatimento) {
        totalDetalhado -= UtilitarioFinanceiro.valorDecimal2Casas(d.valor);
      }
    });

    if (UtilitarioFinanceiro.valorDecimal2Casas(totalDetalhado) !== UtilitarioFinanceiro.valorDecimal2Casas(tituloFinanceiroBase.titulo.saldo)) {
      return false;
    }

    return true;
  }

  function verificarTipoBoleto(meioPagamento: EMeioPagamento):boolean {
    return (meioPagamento === EMeioPagamento.BoletoBancario || meioPagamento === EMeioPagamento.Tributo || meioPagamento === EMeioPagamento.PagamentoConcessionario);
  }

  return {
    tituloFinanceiroBase,
    limparBoleto,
    limparCheque,
    limparStatus,
    limparTela,
    atualizarStatus,
    tituloPendente,
    obterSazonalidade,
    obterDescricaoSazonalidade,
    atualizarUltimoVencimento,
    atualizarSaldo,
    atualizarSaldoPeloDetalhamento,
    obterMensagemRepeticao,
    obterCentrosCusto,
    obterMarcadores,
    obterTotalDetalhado,
    checkRepetir,
    obterClasseStatus,
    criarEvento,
    popularDadosEventos,
    adicionaFiltroStatusPendente,
    adicionaFiltroStatusBaixado,
    adicionaFiltroVencimentoHoje,
    adicionaFiltroVencimentoEsteMes,
    adicionaFiltroVencimentoAtrasados,
    adicionaFiltroBaixadosHoje,
    adicionaFiltroBaixadosEsteMes,
    adicionaFiltroBaixadosAtrasados,
    obtemValorTitulo,
    verificarDetalhesPagamento,
    verificarTipoBoleto,
  };
}
