import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e96941fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "ss-informacao-adicional-select ss-tag",
  style: 'background-color: #a0aec1;'
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_campo_carregando = _resolveComponent("campo-carregando")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.aguardarConclusaoCarregamento(_ctx.computedCodigoSelecionado, _ctx.computedCodigosSelecionados))
      ? (_openBlock(), _createBlock(_component_campo_carregando, { key: 0 }))
      : (_openBlock(), _createBlock(_component_a_select, {
          key: 1,
          mode: _ctx.props.varios?'multiple':null,
          value: _ctx.props.varios?_ctx.computedCodigosSelecionados:_ctx.computedCodigoSelecionado,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.props.varios?_ctx.computedCodigosSelecionados = $event:_ctx.computedCodigoSelecionado = $event)),
          onChange: _ctx.change,
          onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.pesquisarUsuario(''))),
          onSearch: _cache[2] || (_cache[2] = ($event: any) => (_ctx.pesquisarUsuario($event))),
          onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selecionarBase.emDigitacao = false)),
          "show-search": "",
          optionFilterProp: "label",
          class: _normalizeClass('ss-tamanho-completo ' + _ctx.props.classCss),
          allowClear: _ctx.props.limparSelecao,
          placeholder: _ctx.props.placeholder,
          filterOption: false,
          "not-found-content": _ctx.selecionarBase.buscandoDados ? undefined : null,
          disabled: _ctx.props.disabled,
          title: _ctx.props.title,
          "data-coluna": _ctx.props.dataAttributeColuna
        }, _createSlots({
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selecionarBase.listaItensConsultaRapida, (usuario) => {
              return (_openBlock(), _createBlock(_component_a_select_option, {
                key: usuario.codigo,
                value: usuario.codigo
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(usuario.textoIdentificacao) + " ", 1),
                  (usuario.informacaoAdicional !== undefined && usuario.informacaoAdicional !== '')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(usuario.informacaoAdicional), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["value"]))
            }), 128))
          ]),
          _: 2
        }, [
          (_ctx.selecionarBase.buscandoDados)
            ? {
                name: "notFoundContent",
                fn: _withCtx(() => [
                  _createTextVNode(" Buscando Registros "),
                  _createVNode(_component_a_spin)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["mode", "value", "onChange", "class", "allowClear", "placeholder", "not-found-content", "disabled", "title", "data-coluna"]))
  ]))
}